import { toast } from "react-toastify";
import api from "../services/api"

let running = false;

async function getEmployeeData({ employee_id }) {
    if (!running) running = true;
    
    console.log({ employee_id })

    try {
        if (!employee_id) throw new Error("'id' do funcionário não pode ser vazio.");
        const response = await api.get("/get_employee_data?id=" + employee_id);
        return response;
    } catch (error) {
        console.error(error);
        toast.error(error.response.data.message);
    }
    running = false;
    return;
}

export default getEmployeeData