import LogoEUA from '../images/bandeira_eua.svg'
import LogoEspanha from "../images/bandeira-espanha_1.svg"
import LogoBrasil from "../images/bandeira-do-brasil_1.svg"
export const options = [
    { value: 'pt', label: 'Português (BR)', img: LogoBrasil },
    { value: 'es', label: 'Espanhol (ES)', img: LogoEspanha },
    { value: 'en', label: 'English (EN)', img: LogoEUA }
]
export const Vcard = (name, phone, userId, email) => {

    return `
BEGIN:VCARD
VERSION:3.0
REV:2022-12-02T11:04:11Z
URL:${process.env.REACT_APP_URL_EMPLOY}${userId}
NOTE;CHARSET=utf-8:Cartão Digital Grendene
N;CHARSET=utf-8:${name};;;;
FN;CHARSET=utf-8:${name}
TEL;PREF:${phone}
ORG:Grendene;
URL;TYPE=Link:${process.env.REACT_APP_URL_EMPLOY}${userId}
EMAIL;INTERNET;PREF:${email}
END:VCARD
`
}

export const styleSelect = {
    menuList: (styles) => {
        return {
            ...styles,
            backgroundColor: "transparent",
            border: "0"
        }
    },
    control: (styles) => {
        return {
            ...styles,
            backgroundColor: "transparent",
            border: "0"
        }

    },
    indicatorSeparator: (styles) => {
        return {
            ...styles,
            color: "white",
            backgroundColor: "transparent"
        }
    },

    indicatorsContainer: (styles) => {
        return {
            ...styles,
            color: "white",

            ':hover': {
                color: "white"
            },
        }
    },
    dropdownIndicator: (provided) => ({
        ...provided,

        "svg": {

            fill: "white",
        }
    }),
    placeholder: (styles) => {
        return {
            ...styles,
            color: "white",

        }

    },
    container: (styles) => {
        return {
            ...styles,
            minWidth: "50px",
            position: "absolute",
            right: "0",
            top: "14px"
        }
    },
    singleValue: (styles, { data }) => {
        return {
            ...styles,
            color: "#3542C2",

        }
    }
}
