import { QRCodeCanvas } from 'qrcode.react';
import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ReactSelect from 'react-select';
import { toast } from 'react-toastify';
import { Spinner } from 'reactstrap';
import './App.css';
import {
  Border,
  BusinessCard,
  CloseCardButton,
  ContentBusinessCard,
  FooterTopCard,
  HeaderBusinessCard,
  ImageArea,
  ImgCircle,
  InfoItems,
  InfoItemsArea,
  InfoUser,
  LabelInfo,
  ScrollRow,
  TopBusinessCard
} from './AppStyle';
import BlankProfile from './assets/blankProfile.png';
import { Vcard, options, styleSelect } from './constants';
import SafeContact from './images/Vector.svg';
import LogoBrasil from './images/bandeira-do-brasil_1.svg';
import LogoEspanha from './images/bandeira-espanha_1.svg';
import LogoEUA from './images/bandeira_eua.svg';
import bordaBottomAzul from './images/bordaBottomAzul.svg';
import bordaTopAzul from './images/bordaTopAzul.svg';
import close from './images/close.svg';
import phone from './images/fone.svg';
import GrendeneFabric from './images/grendeneFabric.png';
import logoGrendene from './images/logoGrendene.svg';
import msg from './images/msg.svg';
import IconQr from './images/qrcode.svg';
import whats from './images/whats.svg';
import getEmployeeData from './requests/getEmployeeData';
const EMPTY_EMPLOYEE = {
  phone: '',
  name: '',
  whatsapp: '',
  linkedin: '',
  image: ''
};
const EMPTY_EMPLOYEES = {
  pt: {},
  en: {},
  es: {}
};

function App() {
  // States
  const [employees, setEmployees] = useState(EMPTY_EMPLOYEES);
  const [employee, setEmployee] = useState(EMPTY_EMPLOYEE);
  const [loading, setLoading] = useState(false);
  const [language, setLanguage] = useState('pt');
  const [languageActual, setlanguageActual] = useState('');
  const { t, i18n } = useTranslation('common');

  //refs
  const topBusinessCardRef = useRef(null);
  const bottomBusinessCardRef = useRef(null);

  // functions
  function scrollToTopCard() {
    topBusinessCardRef.current?.scrollIntoView({ behavior: 'smooth' });
  }

  function scrollToBottomCard() {
    bottomBusinessCardRef.current?.scrollIntoView({ behavior: 'smooth' });
  }

  function numberFormat() {
    const regex = /^([0-9]{2})([0-9]{4,5})([0-9]{4})$/;

    let regexNumber = '';

    if (
      employee.save_contact_with === null ||
      employee.save_contact_with === 'PHONE_NUMBER'
    ) {
      regexNumber = employee?.phone?.replace(/[^0-9]/g, '').slice(0, 11);
    } else if (employee.save_contact_with === 'WHATSAPP') {
      regexNumber = employee?.whatsapp?.replace(/[^0-9]/g, '').slice(0, 11);
    } else {
      toast.error('Error ao formatar número para contato');
    }

    const numberFormat = regexNumber?.replace(regex, '($1) $2 $3');
    return numberFormat;
  }

  function urlCreate(event) {
    if (employee.name)
      return `data:text/vcard,${encodeURI(
        Vcard(
          employee.name,
          `+55${numberFormat()}`,
          params.userId,
          employee.email
        )
      )}`;
  }

  // Hooks
  const params = useParams();

  // UseEffects
  useEffect(() => {
    setLoading(true);
    scrollToBottomCard();

    getEmployeeData({ employee_id: params.userId })
      .then((response) => {
        if (!response) return;

        if (response.status === 200) {
          setEmployees(response.data);
        }
      })
      .finally(() => setLoading(false));
  }, [params]);

  useEffect(() => {
    switch (language) {
      case 'pt':
        if (Object.keys(employees.pt).length > 0) {
          setEmployee(employees.pt);
        }

        break;

      case 'en':
        if (employees.en) {
          setEmployee(employees.en);
        } else {
          setEmployee({ ...employees.pt, language: 'en' });
        }

        break;

      case 'es':
        if (employees.es) {
          setEmployee(employees.es);
        } else {
          setEmployee({ ...employees.pt, language: 'es' });
        }

        break;

      default:
        break;
    }
  }, [language, employees]);

  useEffect(() => {
    switch (language) {
      case 'pt':
        setlanguageActual(LogoBrasil);

        break;

      case 'en':
        setlanguageActual(LogoEUA);

        break;

      case 'es':
        setlanguageActual(LogoEspanha);

        break;

      default:
        break;
    }
  }, [language]);

  const downloadQrCode = () => {
    const canvas = document.getElementById('canvasQr');
    canvas.size = '500';

    const pngUrl = canvas
      .toDataURL('image/png')
      .replace('image/png', 'image/octet-stream');

    let downloadLink = document.createElement('a');

    downloadLink.href = pngUrl;
    downloadLink.download = `${employee?.name
      ?.split(' ')
      .join('_')}_qrcode.png`;

    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  const changeLanguage = (event) => {
    setlanguageActual(event.value);
    setLanguage(event.value);
    i18n.changeLanguage(event.value);
  };

  const Qrcode = () => {
    return (
      <QRCodeCanvas
        id="canvasQr"
        style={{
          width: '200px',
          height: '200px'
        }}
        size={500}
        renderAs="canvas"
        value={`${window.location.href}`}
      />
    );
  };

  return (
    <div
      className="m-0 p-2 h-100 d-flex justify-content-center align-items-center flex-column"
      style={{
        backgroundColor: '#F8F8F8',
        backgroundImage: `url(${GrendeneFabric})`,
        backgroundSize: 'cover'
      }}
    >
      {loading ? (
        <div
          style={{
            position: 'absolute',
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <Spinner size="lg" color="light" />
        </div>
      ) : (
        <></>
      )}

      <ScrollRow>
        <TopBusinessCard
          md={3}
          xs={12}
          style={{
            opacity: loading ? 0.3 : 1,
            position: 'relative'
          }}
          className="p-0"
        >
          <div style={{ width: '100%', height: '50px' }}>
            <div ref={topBusinessCardRef}></div>

            <CloseCardButton src={close} onClick={scrollToBottomCard} />
          </div>

          <div
            style={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
          >
            <Qrcode />
          </div>

          <div style={{ width: '100%', height: '120px' }}>
            <FooterTopCard>
              <div style={{ position: 'relative' }}>
                <div
                  style={{
                    position: 'absolute',
                    margin: '1em',
                    left: '45%',
                    top: '45%',
                    transform: 'translate(-50%, -50%)',
                    borderRadius: '10px',
                    display: 'grid',
                    cursor: 'pointer',
                    width: '60px',
                    height: '62px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    backgroundColor: 'white'
                  }}
                  onClick={downloadQrCode}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      marginTop: '0.8em'
                    }}
                  >
                    <img src={IconQr} width="20px" alt="QR Code" />
                  </div>
                  <p style={{ color: '#3542C2', fontSize: '12px' }}>
                    {t('cardOnline.download')}
                  </p>
                </div>
                <Border
                  src={bordaBottomAzul}
                  style={{ marginBottom: '-3px' }}
                />
              </div>
            </FooterTopCard>
          </div>
        </TopBusinessCard>

        <BusinessCard
          ref={bottomBusinessCardRef}
          md={3}
          xs={12}
          style={{
            opacity: loading ? 0.3 : 1,
            position: 'relative'
          }}
          className="p-0"
        >
          <div ref={bottomBusinessCardRef}>
            <HeaderBusinessCard>
              <div
                style={{
                  position: 'absolute',
                  margin: '1em',
                  left: 0,
                  borderRadius: '10px',
                  display: 'grid',
                  cursor: 'pointer',
                  width: '60px',
                  height: '62px',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'white'
                }}
                onClick={scrollToTopCard}
              >
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: '0.8em'
                  }}
                >
                  <img src={IconQr} width="20px" alt="QR Code" />
                </div>
                <p style={{ color: '#3542C2', fontSize: '12px' }}>
                  {t('cardOnline.see-qrcode')}
                </p>
              </div>

              <Border src={bordaTopAzul} style={{ marginTop: '-3px' }} />
              <ReactSelect
                styles={{
                  ...styleSelect,
                  menu: (aa, bb) => {
                    return (bb.theme.colors = 'transparent');
                  }
                }}
                placeholder={<img src={languageActual} alt="country" />}
                options={options}
                isLoading={loading}
                defaultValue={options[0]}
                onChange={(e) => changeLanguage(e)}
                formatOptionLabel={(country) => (
                  <div className="country-option">
                    <img src={country.img} alt="country" />
                  </div>
                )}
              />

              <img
                className="img-fluid"
                src={logoGrendene}
                style={{
                  position: 'absolute',
                  marginTop: '20px'
                }}
                alt="logo grendene"
              />

              <ImageArea>
                <ImgCircle
                  className="img-fluid"
                  style={{
                    objectFit: 'cover',
                    width: '100%',
                    height: '100%',
                    borderRadius: '100%'
                  }}
                  src={employee?.image || BlankProfile}
                  alt="funcionario"
                />
              </ImageArea>
            </HeaderBusinessCard>

            <ContentBusinessCard
              style={{
                marginTop: '-1em'
              }}
            >
              <span
                style={{
                  font: 'normal normal bold 22px/27px Montserrat',
                  color: '#19227D',
                  marginBottom: '10px',
                  textAlign: 'center'
                }}
              >
                {employee.name ? employee.name : ''}
              </span>
              <InfoUser style={{ marginBottom: '1em' }}>
                {' '}
                {employee.role ? employee.role : ''}{' '}
                {employee.department?.trim() ? '| ' + employee.department : ''}{' '}
              </InfoUser>
            </ContentBusinessCard>

            <InfoItemsArea>
              <InfoItems>
                <LabelInfo>{t('cardOnline.telephone')}</LabelInfo>
                <ImgCircle
                  className="img-fluid"
                  src={phone}
                  onClick={
                    employee.phone
                      ? () => window.open('tel:' + employee.phone)
                      : () => toast.warning(t('cardOnline.phone-missing'))
                  }
                />
              </InfoItems>

              <InfoItems>
                <LabelInfo>{t('cardOnline.whatsapp')}</LabelInfo>
                <ImgCircle
                  className="img-fluid"
                  src={whats}
                  onClick={
                    employee.whatsapp
                      ? () =>
                          window.open(
                            'https://api.whatsapp.com/send?phone=55' +
                              employee.whatsapp
                          )
                      : () => toast.error(t('cardOnline.whatsapp-missing'))
                  }
                />
              </InfoItems>
            </InfoItemsArea>

            <InfoItemsArea>
              <InfoItems>
                <LabelInfo>{t('cardOnline.email')}</LabelInfo>
                <ImgCircle
                  className="img-fluid"
                  src={msg}
                  onClick={
                    employee.email
                      ? () => window.open('mailto:' + employee.email)
                      : () => toast.warning(t('cardOnline.email-missing'))
                  }
                />
              </InfoItems>

              <InfoItems>
                <LabelInfo>{t('cardOnline.contact')}</LabelInfo>
                <ImgCircle
                  className="img-fluid"
                  src={SafeContact}
                  onClick={
                    employee.email
                      ? () => window.open(urlCreate())
                      : () => toast.warning(t('cardOnline.email-missing'))
                  }
                />
              </InfoItems>
            </InfoItemsArea>
          </div>
        </BusinessCard>
      </ScrollRow>
    </div>
  );
}

export default App;
