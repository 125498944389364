import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import languagePt from "./translation/pt/common.json"
import languageEs from "./translation/es/common.json"
import languageEn from "./translation/en/common.json"

i18next.init({
  interpolation: { escapeValue: false },
  lng: 'pt',
  resources: {
    en: {
      common: languageEn
    },
    es: {
      common: languageEs
    },
    pt: {
      common: languagePt
    }
  },
});
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <I18nextProvider i18n={i18next}>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path='/:userId' element={<App />} />
        </Routes>
      </BrowserRouter>
    </I18nextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
