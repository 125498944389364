import { forwardRef } from 'react';
import { Button, Col, Row } from 'reactstrap';
import styled from 'styled-components';

export const BusinessCard = styled(Col)`
  height: 93vh;
  background: #f2f2f2;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 0 0 26px 26px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ColRef = forwardRef(({ children, ...props }, ref) => {
  return (
    <Col {...props} ref={ref}>
      {children}
    </Col>
  );
});

export const TopBusinessCard = styled(ColRef)`
  height: 93vh;
  background: white;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 26px 26px 0 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const ScrollRow = styled.div`
  max-height: 93vh;
  width: 100%;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  ::-webkit-scrollbar {
    background-color: transparent;
  }
`;

export const Border = styled.img`
  width: 100%;
`;

export const HeaderBusinessCard = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

export const ImageArea = styled.div`
  top: -60px;
  width: 110px;
  height: 110px;
  // background-color: white;
  background-image: url(${(props) => props.image});
  position: relative;
  border: 3px solid #19227d;
  border-radius: 100%;
`;

export const ContentBusinessCard = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center;
`;

export const InfoItemsArea = styled.div`
  display: flex;
  flex-direction: row;
  -webkit-box-pack: center;
  justify-content: center;
  margin-top: 0px;
  width: 100%;
  padding-inline: 50px;
`;

export const InfoItems = styled(Col)`
  display: flex;
  flex-direction: column;
  -webkit-box-pack: center;
  -webkit-box-align: center;
  align-items: center;
`;

export const LabelInfo = styled.span`
  text-align: center;
  font-style: normal;
  font-variant: normal;
  font-size: 12px;
  font-family: 'Montserrat';
  letter-spacing: 0px;
  color: #19227d;
`;

export const ActionInfo = styled(Button)`
  background-color: transparent;
  border: none;
  &:hover {
    background-color: transparent;
  }
`;

export const ImgCircle = styled.img`
  width: 60%;
  height: 72%;
  :hover {
    cursor: pointer;
  }
`;

export const InfoUser = styled.span`
  width: 215px;
  text-align: center;
  font: normal normal medium 13px/16px Montserrat;
  color: #a4a1be;
`;

export const CloseCardButton = styled.img`
  position: absolute;
  top: 1rem;
  right: 1rem;

  width: 40px;
  height: 40px;

  display: flex;
  align-items: center;
  justify-content: center;

  :hover {
    cursor: pointer;
  }
`;

export const FooterTopCard = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: white;
`;
